import React from "react"
import "../styles/Footer.css"
import circlepng from "../images/circlepng.png"
import gdpr from "../images/gdpr.png"
import { Link } from "gatsby"
import { registerURL, loginURL, blogURL } from "./Const"

export default function Footer() {
  return (
    <div className="footer-container" id="footer-container">
      <div className="circle-footer">
        <img src={circlepng} alt="circle" />
      </div>
      {/* Left side of the footer */}
      <div className="left-footer-elements">
        {/* Footer logo */}
        <div className="footer-logo">
          <Link to="/">
            <img src="/logo2.svg" alt="logo" />
          </Link>
        </div>
        {/* First list */}
        <div className="footer-lists">
          <div className="footer-list-1">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <a href={blogURL}>Blog</a>
              </li>
              <li>
                <Link to="/pricing" alt="Pricing">
                  Pricing
                </Link>
              </li>
            </ul>
          </div>
          {/* Second list */}
          <div className="footer-list-2">
            <ul>
              <li>
                <Link to="/contact" alt="Contact">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" alt="Privacy Policy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" alt="Terms">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Right side of the footer */}
      <div className="right-footer-elements">
        {/* Start for free button */}
        <a className="nav-btn start-for-free" href={registerURL} alt="test">
          <span className="footer-btn-text">Start for Free</span>
        </a>
        {/* Footer login */}
        <a className="footer-login" href={loginURL}>
          Login
        </a>
        <br />
        <div className="gdpr-container">
          <img src={gdpr} alt="gdpr" className="gdpr-img" />
        </div>
        {/* Footer copyright */}
        <p className="footer-copyright">Copyright 2020 Optinian LLC</p>
      </div>

      {/* Global site tag (gtag.js) - Google Analytics */}

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-JF3ZEVGDQD"
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
      (function(d,t) {
        var BASE_URL = "https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src= BASE_URL + "/packs/js/sdk.js";
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: 'c1obU2uEsG2LfusG9LTLcEsF',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
      `,
        }}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "3xlwngh4or");`,
        }}
      />

      {/*  Global site tag (gtag.js) - Google Analytics */}

      <script
        dangerouslySetInnerHTML={{
          __html: `  window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-JF3ZEVGDQD');`,
        }}
      />
    </div>
  )
}
