import React, { useState } from "react"
import "../styles/Header.css"
import { Link } from "gatsby"
import { registerURL, loginURL } from "./Const"

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false)

  const toggleNavbar = () => {
    setIsOpen(!isOpen)
  }

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     let mainNav = document.getElementById("mainNav")
  //     let mainNavLogo = document.getElementById("mainNavLogo")
  //     let dropdownButton = document.getElementById("dropdownButton")
  //     mainNav.classList.add("scrollNav")
  //     mainNavLogo.classList.add("logo-scroll")
  //     dropdownButton.classList.add("dropdown-button-scroll")
  //     if (window.scrollY === 0) {
  //       mainNav.classList.remove("scrollNav")
  //       mainNavLogo.classList.remove("logo-scroll")
  //       dropdownButton.classList.remove("dropdown-button-scroll")
  //     }
  //   })
  // })

  return (
    <nav className="main-nav" id="mainNav">
      {/* Logo */}
      <Link to="/">
        <span>
          <img
            className="main-nav-logo"
            id="mainNavLogo"
            src="/logo2.svg"
            alt="logo"
          ></img>
        </span>
      </Link>
      {/* Navigation links */}
      <div
        className="dropdown-button"
        id="dropdownButton"
        onClick={toggleNavbar}
      >
        <div className="dropdown-span1"></div>
        <div className="dropdown-span2"></div>
        <div className="dropdown-span3"></div>
      </div>
      <ul className={`${isOpen}`}>
        <li className="rwd-pn-remove">
          <Link
            to="/#push-notification"
            onClick={toggleNavbar}
            alt="What is Push Notification?"
          >
            What is Push Notification
          </Link>
        </li>
        <li>
          <Link to="/#is-it-for-me" onClick={toggleNavbar} alt="Is it for me?">
            Is it for me?
          </Link>
        </li>
        <li>
          <Link
            to={"/#how-does-it-work"}
            onClick={toggleNavbar}
            alt="How does it Works?"
          >
            How does it Work?
          </Link>
        </li>
        <li>
          <Link to="/#features" onClick={toggleNavbar} alt="Features">
            Features
          </Link>
        </li>
        <li>
          <Link to="/pricing" onClick={toggleNavbar} alt="Pricing">
            Pricing
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={toggleNavbar} alt="Contact us">
            Contact Us
          </Link>
        </li>
        <li>
          <a href={loginURL} onClick={toggleNavbar} alt="Login">
            Login
          </a>
        </li>
        {/* Start for free button */}
        <li>
          <a
            href={registerURL}
            className="nav-btn"
            onClick={toggleNavbar}
            alt="Start for free"
          >
            <span className="showcase-btn-text">Start for Free</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}
